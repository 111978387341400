import React from 'react';
import {UserForm} from '../components/UserForm/UserForm';

export const  UserFormRoute = () => {
    return(
<div>
    <h1>User Registration Form</h1>
    <UserForm/>
</div>
    );
}