import React from 'react';
import UserDetails from '../components/UserDetails/UserDetails';

export const UserDetailsRoute = () => {
  return (
    <div>
      <UserDetails />
    </div>
  );
}


